<template>
  <div ref="wrapper">
    <div class="err" v-if="!loading">
      <div class="err-list">
        <h3>登录失败</h3>
        <h3>请退出重新登录</h3>
      </div>
    </div>
    <loading-temp :loading-text="'登录中...'" :loading="loading"></loading-temp>
  </div>
</template>

<script>
import { fetUserLogin } from "../../server/user";
import loadingTemp from "../../components/loading";
import { mapMutations } from "vuex";
import { getToken } from "../../unit/token";

export default {
  name: "index",
  data() {
    return {
      loading: true,
    };
  },
  components: {
    loadingTemp,
  },
  created() {
    this.login();
  },
  methods: {
    ...mapMutations(["setUserInfo"]),
    getParams() {
      const params = {};
      let query = window.location.href;
      if (query.includes("?")) {
        let index = query.indexOf("?");
        let str = query.slice(index + 1);
        let arr = str.split("&");
        for (let i = 0; i < arr.length; i++) {
          let q = arr[i].split("=");
          if (q.length === 2) {
            params[q[0]] = q[1];
          }
        }
      }
      return params;
    },
    login() {
      let da = {
        grant_type: "social",
        scope: "all",
        source: "WECHAT_ENTERPRISE",
      };
      let sendData = {
        ...this.getParams(),
        ...da,
      };
      let arr = Object.keys(this.getParams());
      if (arr.length === 0) {
        this.loading = false;
        return;
      }
      fetUserLogin(sendData)
        .then((res) => {
          if (res.access_token && res) {
            this.setUserInfo(res);
            window.localStorage.setItem("token", res.access_token);
            getToken({ ...res });
            if (sendData.path === "birthdayWishes") {
              this.$router.replace("/birthdayWishes");
            } else {
              this.$router.replace("/");
            }
          } else {
            this.$toast(res.error_description);
            this.loading = false;
          }
        })
        .catch(() => {
          this.$toast("请求出错,请退出重新登录");
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.err {
  text-align: center;
  font-size: 18px;
  display: flex;
  align-items: center;
  height: 100vh;
  justify-content: center;
  color: rgba(0, 0, 0, 0.5);

  h3 {
    margin: 10px;
  }
}
</style>
